import React, { useState } from 'react';
import loadable from '@loadable/component';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import './App.css';
const CompletedPage = loadable(() => import('./components/Report/CompletedPage'));
const SurveyPage = loadable(() => import('./components/Survey/Survey'));
const Home = loadable(() => import('./components/Home/Home'));



const history = createBrowserHistory();

const clientData = {
    "client": "IHK Berlin",
    "selectedParameters": [
        "Herzlichkeit",
        "Offenheit",
        "Positivität"
    ],
    "CompanyShwartzScore": 290,
    "companyClasses": {
        "A": 4,
        "A1": 5,
        "A2": 4,
        "A3": 3,
        "A4": 4,
        "A5": 3,
        "C": 6,
        "C1": 2,
        "C2": 5,
        "C3": 3,
        "C4": 6,
        "C5": 6,
        "E": 5,
        "E1": 6,
        "E2": 6,
        "E3": 5,
        "E5": 6,
        "E6": 4,
        "N": 3,
        "N1": 4,
        "N2": 4,
        "N3": 3,
        "N4": 4,
        "N6": 4,
        "O": 6,
        "O1": 6,
        "O2": 5,
        "O3": 4,
        "O4": 2,
        "O5": 5
    }
};

export default function App () {
    const [completed, setCompleted] = useState(false);
    const [surveyResult, setSurveyResult] = useState('');
    const primaryColor = "#009DCF";
    const secondaryColor = "#17B2A6";
    return (
        <Router history={history}>
            <Switch>

                <Route path="/fitcheck" >
                    <SurveyPage completed={completed} setSurveyResult={setSurveyResult} setCompleted={setCompleted} primaryColor={primaryColor} />
                </Route>

                <Route path="/report" >
                    <CompletedPage surveyResult={surveyResult} clientData={clientData} primaryColor={primaryColor} secondaryColor={secondaryColor} />
                </Route>

                <Route exact path="/" >
                    <Home />
                </Route>

            </Switch>
        </Router>
    );
}


